import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
Swiper.use([Autoplay, Navigation, Pagination]);

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';


// (function () {
    // const breakpoint = window.matchMedia('(min-width: 1280px)');

    // let mySwiper;

    // const breakpointChecker = function () {
    //     if (breakpoint.matches === true) {
    //         if (mySwiper !== undefined) {
    //             mySwiper.destroy(true, true);
    //         }

    //         return;
    //     } else if (breakpoint.matches === false) {
    //         return enableSwiper();
    //     }
    // };

    // const enableSwiper = function () {
    //     mySwiper = new Swiper('.swiper-container', {
    //         loop: true,
    //         slidesPerView: 'auto',
    //         autoplay: {
    //             delay: 3000,
    //         },
    //         navigation: {
    //             nextEl: '.swiper-button-next',
    //             prevEl: '.swiper-button-prev',
    //         },
    //         breakpoints: {
    //             640: {
    //                 slidesPerView: 1,
    //             },
    //             750: {
    //                 slidesPerView: 'auto',
    //             }
    //         }
    //     });
    // };

    // breakpoint.addListener(breakpointChecker);
    // breakpointChecker();




// })();


var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.querySelector('.prod-slide__overlay');

            if (linkEl) {
                size = linkEl.getAttribute('data-size').split('x');
                item = {
                    src: linkEl.getAttribute('href'),
                    w: parseInt(size[0], 10),
                    h: parseInt(size[1], 10)
                };
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if(pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {
            closeOnVerticalDrag: false,
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();

        document.querySelector('.pswp__button--close').addEventListener('click', function(){
            // console.log('test');
            gallery.close()
        })

    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};





let mySwiper = new Swiper('.product__slider .swiper-container', {
    loop: true,
    slidesPerView: 'auto',
    loopedSlides: 4,
    slidesPerGroup: 4,
    centeredSlides: false,
    speed: 300,

    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },

    // on: {
    //     afterInit: function(mySwiper){
    //         if(!!mySwiper.imagesToLoad[0].clientHeight){
    //             centerBtn([mySwiper.navigation.nextEl,mySwiper.navigation.prevEl], mySwiper.imagesToLoad[0].clientHeight);
    //         }
    //     },

    //     resize: function(mySwiper){
    //         if(!!mySwiper.imagesToLoad[0].clientHeight){
    //             centerBtn([mySwiper.navigation.nextEl,mySwiper.navigation.prevEl], mySwiper.imagesToLoad[0].clientHeight);
    //         }
    //     },

    // },
    breakpoints: {
        1023: {
            loopedSlides: 4,
            slidesPerGroup: 4
        },
        768: {
            loopedSlides: 3,
            slidesPerGroup: 3
        },
        320: {
            loopedSlides: 2,
            slidesPerGroup: 2
        }
    }
});



function centerBtn(slider){
    const   btns = slider.querySelectorAll('.swiper-button'),
            imgHeight = slider.querySelector('.prod-slide__inner').offsetHeight;

    [...btns].forEach((btn) => {
        btn.style.top = imgHeight / 2 + 'px';
        btn.style.opacity = 1;
    })
}


document.addEventListener("DOMContentLoaded", function() {
    const sliderCollection = document.querySelector('.product__slider');
    if(!!sliderCollection){
        centerBtn(sliderCollection);
    }
});
window.addEventListener('resize', function(){
    const sliderCollection = document.querySelector('.product__slider');
    if(!!sliderCollection){
        centerBtn(sliderCollection);
    }
})


// execute above function
initPhotoSwipeFromDOM('.product__slider .swiper-container:not(.without-thumbnail)');



const   modVideo = document.getElementById('video-modal'),
        modVideoTrigger = document.querySelector('.intro__play'),
        iframe = document.querySelector('.yt-video iframe');

if(!!modVideo && !!modVideoTrigger){

    // console.log('video play and pause TODO');

    const modTriggers = [modVideoTrigger, document.querySelector('.modal__close')];

    modTriggers.forEach((el, index) => {
        el.addEventListener('click', () => {
            if(modVideo.classList.contains("modal_is-open")){
                modVideo.classList.remove("modal_is-open");
                modVideo.classList.remove("modal_is-animated");
                if(!!iframe){
                    const videoYoutubeTargetOriginUrl = iframe.src;
                    iframe.src = videoYoutubeTargetOriginUrl.replace('autoplay=1', 'autoplay=0');
                }
            } else {
                modVideo.classList.add("modal_is-open");
                modVideo.classList.add("modal_is-animated");
                if(!!iframe){
                    const videoYoutubeTargetOriginUrl = iframe.src;
                    iframe.src = videoYoutubeTargetOriginUrl.replace('autoplay=0', 'autoplay=1');
                }

            }
        })
    });

}
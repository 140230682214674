const testForms = document.querySelectorAll(".form_js-validate--1111111");

window.formComponent = {
    init: () => {
        testForms.forEach((form) => {
            const fields = form.querySelectorAll("[data-validate]");
            formComponent.validationOnSubmit(fields, form);
            formComponent.checkActive(fields, form);
            formComponent.validateOnEntry(fields, form);
        });
    },

    modelValidation: (validationFields, form) => {
        validationFields.forEach((field) => {
            formComponent.validateFields(field);
        });
        let checks = form.querySelector(".error:not(.hidden)");
        if (form.contains(checks)) {
            // here you can add some error msg or else ...
        } else {
            // if (form.id === "formWinner") {
            //     showConfirmModal();
            // } else if (form.id === "form1") {
            //     formMainSend();
            // }

            nextPage();
        }
    },

    validationOnSubmit: (validationFields, form) => {
        form.addEventListener("submit", (e) => {
            e.preventDefault();

            formComponent.modelValidation(validationFields, form);
        });
    },

    validateOnEntry: (validationFields, form) => {
        validationFields.forEach((field) => {
            field.addEventListener("input", (event) => {
                formComponent.validateFields(field);
            });
        });
    },
    checkActive: (validationFields, form) => {
        validationFields.forEach((field) => {
            // console.log(field);
            field.onfocus = function() {
                field.closest('.form-control').classList.add('is-active');
                // console.log(this);
            }
            field.onblur = function() {
                if(field.value == '')
                    field.closest('.form-control').classList.remove('is-active');
            }

        });
    },

    validateFields: (input) => {
        const data = input.dataset.validate;

        if (~data.indexOf("no-empty")) {
            if (input.value.trim() === "") {
                formComponent.setStatus(input, "error");
            } else {
                formComponent.setStatus(input, "success");
            }
        }
        if (~data.indexOf("number")) {
            const numReg = /^\d+$/;
            if (numReg.test(input.value) && input.value.trim() !== "") {
                formComponent.setStatus(input, "success");
            } else {
                formComponent.setStatus(input, "error");
            }
        }
        if (~data.indexOf("email")) {
            const emailReg = /\S+@\S+\.\S+/;
            if (emailReg.test(input.value) && input.value.trim() !== "") {
                formComponent.setStatus(input, "success");
            } else {
                formComponent.setStatus(input, "error");
            }
        }

        if (~data.indexOf("select")) {
            if (input.value === "") {
                formComponent.setStatus(input, "error");
            } else {
                formComponent.setStatus(input, "success");
            }
        }
        if (~data.indexOf("checkbox")) {
            // console.log(input.checked);
            if (input.checked) {
                formComponent.setStatus(input, "success");
            } else {
                formComponent.setStatus(input, "error");
            }
        }

        if (~data.indexOf("phone")) {
            const phoneReg =
                /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
            if (phoneReg.test(input.value) && input.value.trim() !== "") {
                formComponent.setStatus(input, "success");
            } else {
                formComponent.setStatus(input, "error");
            }
        }
    },

    // set/remove class final fx
    setStatus: (field, status) => {
        const errorMessage = field.parentElement.querySelector(".error");

        // console.log(errorMessage);
        if (status === "success") {
            field.classList.remove("input-error");
            errorMessage.classList.add("hidden");
        }
        if (status === "error") {
            field.classList.add("input-error");
            errorMessage.classList.remove("hidden");
        }
    },
};

const  cbx1 = document.getElementById('agree');
if(!!cbx1){
    cbx1.addEventListener('change', () => {
        checkParam();
    });
    function checkParam() {
        const btn = document.getElementById('submit');

        if (cbx1.checked){
            btn.removeAttribute('disabled');
        } else {
            btn.setAttribute('disabled', '');
        }
    }
}

formComponent.init();



window.nextPage = function(){
    console.log('done');
}

document.addEventListener("DOMContentLoaded", function() {

    const   valFields = document.querySelectorAll('.form-control__field','.form-control__number'),
            valFieldsNum = document.querySelectorAll('.form-control__number');


    if(!!valFields || !!valFieldsNum){
        let arrs = [];
        Array.from(valFields).forEach((field) => {
            arrs.push(field);
        });
        Array.from(valFieldsNum).forEach((field) => {
            arrs.push(field);
        });

        if(arrs.length > 0){
            arrs.forEach((field) => {
                field.onfocus = function() {
                    field.closest('.form-control').classList.add('is-active');
                }
                field.onblur = function() {
                    if(field.value == ''){
                        field.closest('.form-control').classList.remove('is-active');
                    }
                }
                if(field.value != ''){
                    field.closest('.form-control').classList.add('is-active');
                }
            });
        }
    }
})
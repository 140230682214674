window.scrollToHash = function(elem) {

    console.log(elem);
   let
        w = elem.getBoundingClientRect().top,
        t = w + 120,
        start = null,
        hash = elem.id,
        V = 1;

   console.log(t);

   requestAnimationFrame(step);
   function step(time) {
        if (start === null) start = time;
        var progress = time - start,
            r = (t < 0 ? Math.max(w - progress/V, w + t) : Math.min(w + progress/V, w + t));
        window.scrollTo(0,r);
        if (r != w + t) {
            requestAnimationFrame(step)
        } else {
            location.hash = hash  // URL с хэшем
        }
    }

}

window.scrollTo = function(element) {
    window.scroll({
      behavior: 'smooth',
      top: element.offsetTop - 80
    });
}





document.addEventListener("DOMContentLoaded", function() {
    const ftpPseudoLink = document.querySelectorAll('a[href*="ftp"]');
    if(ftpPseudoLink.length > 0){
        [...ftpPseudoLink].forEach((elem) => {
            let newLink = elem.href.replace('ftp:/','');
            elem.href = newLink;
        })
    }
    scrollToEl();


    function scrollToEl(){
        const scrollToElems = document.querySelectorAll('a[href*="#"]:not([href="#"])');
        if(scrollToElems.length > 0){
            scrollToElems.forEach((el, index) => {
                // console.log(el)
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    const   hash = el.hash,
                            target = document.getElementById(hash.substring(1));
                    if(!!target){
                        // console.log(target)
                        scrollTo(target);
                    }
                });

            });
        }
    }

})
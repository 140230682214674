import { isMobile } from "./is-mobile";
import { hideMainScroll } from "./hide-main-scroll";
import { headerHeight } from "./header-height";



(function() {
    var throttle = function(type, name, obj) {
        obj = obj || window;
        var running = false;
        var func = function() {
            if (running) { return; }
            running = true;
             requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    throttle("resize", "optimizedResize");
    throttle("scroll", "optimizedScroll");
})();


(function () {
    const trigger = document.querySelector(".js-menu-trigger");
    const mobMenu = document.querySelector(".js-main-menu__wrapper");
    if (trigger) {
        document.addEventListener("click", function (event) {
            if (mobMenu && document.body.classList.contains("menu-open")) {
                const isClickInside = mobMenu.contains(event.target);
                if (!isClickInside && !trigger.contains(event.target)) {
                    document.body.classList.remove("menu-open");
                    hideMainScroll();
                }
            }

            if (document.body.classList.contains('menu-open') && event.target.closest('a').classList.contains('main-menu__link')){
                document.body.classList.remove("menu-open");
                hideMainScroll(true);
            }
        });
        trigger.onclick = () => {
            document.body.classList.toggle("menu-open");
            hideMainScroll(true);
        };
    }
})();


(function () {
    const lang = document.getElementById("lang");
    const langNav = lang.querySelector(".lang__list");
    if (lang) {
        document.addEventListener("click", function (event) {
            if (langNav && document.body.classList.contains("lang-open")) {
                const isClickInside = langNav.contains(event.target);
                if (!isClickInside && !lang.contains(event.target)) {
                    document.body.classList.remove("lang-open");
                }
            }
        });
        lang.onclick = () => {
            document.body.classList.toggle("lang-open");
        };
    }
})();

function headerChanges() {
    const header = document.getElementById("header");
    if (header && header.classList.contains("header_transparent")) {
        if (pageYOffset > 10) {
            header.classList.add("header_colored");
        } else {
            header.classList.remove("header_colored");
        }
        setTimeout(() => {
            headerHeight();
        }, 400);
    }

}


document.addEventListener("DOMContentLoaded", function () {
    headerChanges();
    headerHeight();
});


window.addEventListener("optimizedScroll", function() {

    headerChanges();

    if (pageYOffset > 10) {
        document.body.classList.add("is-scrolled");
    } else {
        document.body.classList.remove("is-scrolled");
    }
    headerHeight();

});



window.addEventListener("optimizedResize", function() {
    headerHeight();
});


// import './components/hello';
// import  './components/scrollspy';
import './components/header';
import './components/formComponent';
// import textFit from 'textfit';
import './components/carousel';
import './components/video';
import './components/scrollTo';
// import AOS from 'aos';
// AOS.init();
// import $ from "jquery";

// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

const mainWrap = document.querySelector('main');



function toggle(selector,cls){
    let target = document.querySelectorAll(selector);
    if(target){
        target.forEach(text => {
            text.addEventListener('click', function(){
                this.parentElement.classList.toggle(cls);
            });
        });
    }
}
//
toggle('.nav__open','is-open');


//example footer height detect
// function _getAbsoluteHeight(el) {
//     el = (typeof el === 'string') ? document.querySelector(el) : el;
//     var styles = window.getComputedStyle(el);
//     var margin = parseFloat(styles['marginTop']) +
//                  parseFloat(styles['marginBottom']);
//     return Math.ceil(el.offsetHeight + margin);
// }

// //footer height variable
// function setVariableFooterHeight(){
//     setTimeout(() => {
//         const footerH = _getAbsoluteHeight('.footer');

//         if(footerH !== 0){
//             document.documentElement.style.setProperty('--footerHeight', footerH + 'px');
//         }

//     }, 200);
// }

// setVariableFooterHeight();

// window.addEventListener("orientationchange", function() {
//     setVariableFooterHeight();
// });


const videoBlock = document.querySelector('.js-video');

// video with observer
const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            // console.log(entry.target);
            entry.target.play();
            // console.log('video is intersecting -- on');
        } else {
            entry.target.pause();
            // console.log('video out is intersecting -- off');
        }
    })
}, {
    threshold: 0.7
});

function autoplayVideos() {
    let videos = document.querySelectorAll('.js-video-autoplay');

    if (!videos.length) {
        return;
    }

    for (let i = 0; i < videos.length; i++) {
        let video = videos[i];
        let height = video.offsetHeight;
        let parent = video.closest('.js-video');
        let soundControl = parent.querySelector('.js-video-audio-control');


        if (!'IntersectionObserver' in window &&
        !'IntersectionObserverEntry' in window &&
        !'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
            // console.log('not supported observer');
            video.play();
            // console.log('status video - on');
        } else {
            // console.log('push to observe');
            observer.observe(video);

        }

        if (!!soundControl){
            soundControl.addEventListener('click', function(){
                if (video.muted === true) {
                    video.muted = false;
                    soundControl.classList.add('_audio-on');
                } else {
                    video.muted = true;
                    soundControl.classList.remove('_audio-on');
                }
            })
        } else {
            console.log('element sound btn undefine');
        }
    }
}


document.addEventListener("DOMContentLoaded", function() {
    // console.log('begin autoplayvideo')
    // console.log('end autoplayvideo')
    if(!!videoBlock){
        autoplayVideos();
        videoBlock.addEventListener('click', function(){
            window.location.href = "/form.html"
        })
    }
});




export const headerHeight = () => {
    const h = document.getElementById("header");
    // console.log('sdsd');
    if (h) {
        document.documentElement.style.setProperty(
            "--headerHeight",
            h.offsetHeight + "px"
        );
    }
};
